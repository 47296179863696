<template>
  <div class="auth-wrapper auth-v2">
    <b-row class="auth-inner m-0">
      <!-- Login-->
      <b-col lg="6" class="d-flex align-items-start auth-bg px-2 p-lg-5-update">
        <b-col sm="8" md="6" lg="12" class="px-xl-2 mx-auto">
          <div class="custom-width">
            <!-- Brand logo-->
            <div class="d-flex justify-content-between">
              <b-link class="brand-logo-update">
                <DefaultLogo />
              </b-link>
              <ul class="lang-sign-up">
                <locale />
              </ul>
            </div>
            <!-- /Brand logo-->
            <b-card-title title-tag="h2" class="font-weight-bold bt-40">
              {{ $t("login.PasswordUpdated") }}
            </b-card-title>
            <b-card-text class="mb-0">
              {{ $t("login.successfullyMsg") }}
              <br /><br /><br />
            </b-card-text>
            <b-card-text class="text-center mt-2">
              <b-link :to="{ name: 'login' }">
                <feather-icon icon="ChevronLeftIcon" />
                {{ $t("login.BackLogin") }}
              </b-link>
            </b-card-text>
          </div>
        </b-col>
      </b-col>
      <!-- /Login-->
      <!-- Left Text-->
      <b-col lg="6" class="d-none d-lg-flex align-items-center p-5 pd-0">
        <div
          class="w-100 d-lg-flex align-items-center justify-content-center px-5 pd-0"
        >
          <div class="login-bg">
            <LoginImage class="object-login-img" />
          </div>
        </div>
      </b-col>
      <!-- /Left Text-->
    </b-row>
  </div>
</template>

<script>
/* eslint-disable global-require */
import { ValidationProvider, ValidationObserver } from "vee-validate";
import DefaultLogo from "@/views/Common/DefaultLogo.vue";
import {
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BInputGroupPrepend
} from "bootstrap-vue";
import { required, email } from "@validations";
import { togglePasswordVisibility } from "@core/mixins/ui/forms";
import store from "@/store/index";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import LoginImage from "@/assets/images/pages/login-v2.svg";
import Locale from "@/layouts/components/Locale.vue";
export default {
  components: {
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    DefaultLogo,
    ValidationProvider,
    ValidationObserver,

    BInputGroupPrepend,
    LoginImage,
    Locale
  },
  mixins: [togglePasswordVisibility],
  data() {
    return {
      status: "",
      password: "",
      userEmail: "",
      sideImg: require("@/assets/images/pages/forgot-pass-step-2.svg"),
      // validation rulesimport store from '@/store/index'
      required,
      email
    };
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
    imgUrl() {
      if (store.state.appConfig.layout.skin === "dark") {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.sideImg = require("@/assets/images/pages/forgot-pass-step-2.svg");
        return this.sideImg;
      }
      return this.sideImg;
    }
  },
  methods: {
    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: "Form Submitted",
              icon: "EditIcon",
              variant: "success"
            }
          });
        }
      });
    }
  }
};
</script>

<style lang="scss">
@import "@core/scss/vue/pages/page-auth.scss";
@import "./index.scss";
</style>
